<template>
  <div class="vc-page-bg">
    <!--    <div class="main-title">-->
    <!--      <p class="px-2" v-if="!isEndCall && callStep == steps.VIEW_INSTRUCTIONS">BẠN VUI LÒNG ĐỌC HƯỚNG DẪN DƯỚI ĐÂY TRƯỚC KHI THỰC HIỆN GỌI VIDEO-->
    <!--      </p></div>-->
    <div class="main-body" v-if="!isEndCall">
      <div v-if="callStep == steps.VIEW_INSTRUCTIONS" class="vc-modal">
        <h2 class="font-large-1 font-weight-bold text-center">
          Trước khi thực hiện gọi Video, Bạn vui lòng đọc kỹ hướng dẫn sau:
        </h2>
        <div class="detail-instructions">
          <!--          <h2 class="title-instruction">Hướng dẫn thực hiện Video Call</h2>-->
          <ul>
            <li>Thiết bị sẽ yêu cầu quyền truy cập Micrô và Camera.</li>
            <li>
              Vui lòng nhấn vào nút “<span class="font-weight-bolder"
                >Cho phép</span
              >” hoặc “<span class="font-weight-bolder">Allow</span>” để bắt đầu
              cuộc gọi
            </li>
            <li>
              Theo quy định của Bộ thông tin truyền thông, để hoàn tất thủ tục
              đăng ký/cập nhật thông tin thuê bao, Bạn vui lòng thực hiện cuộc
              Video với tổng đài viên.
            </li>
            <!--            <li>Đảm bảo kết nối mạng ổn định</li>-->
            <!--            <li>Trang phục lịch sự</li>-->
          </ul>
        </div>
        <div
          class="font-weight-bold font-medium-3 text-primary d-flex mb-1 align-items-center"
        >
          <img
            src="@/assets/images/video-call/vc-alert.svg"
            alt="alert-cam"
            class="mr-1"
          />
          <p>Lưu ý: cấp quyền truy cập CAMERA cho thiết bị</p>
        </div>
        <div class="font-medium-2">
          <div class="mb-2">
            <p class="font-italic">
              Hướng dẫn trong trường hợp thiết bị của Bạn đang để Tiếng Việt
            </p>
            <div class="d-flex justify-content-center">
              <img
                src="@/assets/images/video-call/allow-mic-vi.png"
                alt="allow-mic-vi"
                class="mt-2 img-video-call"
              />
            </div>
          </div>
          <div>
            <p class="font-italic">
              Hướng dẫn trong trường hợp thiết bị của Bạn đang để Tiếng Anh
            </p>
            <div class="d-flex justify-content-center">
              <img
                src="@/assets/images/video-call/allow-mic-eng.png"
                alt="allow-mic-eng"
                class="mt-2 img-video-call"
              />
            </div>
          </div>
        </div>
        <div class="main-bottom">
          <button class="video-call-btn" @click="onVideoCallClick">
            Bắt đầu gọi video
          </button>
        </div>
      </div>
      <div v-else class="vc-show">
        <p class="font-large-1 font-weight-bold">
          Số thuê bao:
          <span class="text-primary">{{ $route.params.phone }}</span>
        </p>
        <div class="main-video">
          <div id="remoteTrack"></div>
          <!-- <div v-if="callStep == steps.CONNECTING " class="fade-bg" /> -->
          <div
            v-if="callStep == steps.CONNECTING"
            id="localTrack"
            class="local-track-container__before"
          />
          <div v-if="callStep == steps.CONNECTING" class="connecting">
            <div class="caller-avatar">
              <img
                src="@/assets/images/video-call/user-avatar.svg"
                alt="avatar"
              />
            </div>
            <div class="connecting-text">Cuộc gọi đang được kết nối</div>
            <div class="dots">
              <div class="dot-1" />
              <div class="dot-2" />
              <div class="dot-3" />
            </div>
          </div>
          <!-- Show localtrack in here -->
          <div v-if="callStep !== steps.CONNECTING" class="connected">
            <div id="localTrack" class="local-track-container"></div>
          </div>
          <div class="bottom-controls">
            <button
              :disabled="!activeRoom"
              class="control-item"
              @click="onChangeCamera()"
            >
              <img
                src="@/assets/images/video-call/xoay1.png"
                alt="control-item"
              />
            </button>
            <button
              :disabled="!activeRoom"
              class="control-item"
              @click="onToggleMic()"
            >
              <img
                v-if="isMicOn"
                src="@/assets/images/video-call/mic.png"
                alt="control-item"
              />
              <img
                v-else
                src="@/assets/images/video-call/mute-mic.png"
                alt="control-item"
              />
            </button>
            <!--            <button-->
            <!--              :disabled="!activeRoom"-->
            <!--              class="control-item"-->
            <!--              @click="onToggleCamera()"-->
            <!--            >-->
            <!--              <img-->
            <!--                v-if="isCameraOn"-->
            <!--                src="@/assets/images/video-call/video.png"-->
            <!--                alt="control-item"-->
            <!--              />-->
            <!--              <img-->
            <!--                v-else-->
            <!--                src="@/assets/images/video-call/no-video.png"-->
            <!--                alt="control-item"-->
            <!--              />-->
            <!--            </button>-->
            <button class="control-item exit-call" @click="clientStopCall()">
              <img
                src="@/assets/images/video-call/phone.svg"
                alt="control-item"
              />
            </button>
          </div>
          <div v-if="callStep == steps.CONFIRM" class="sign-container-modal">
            <div class="sign-modal">
              <div class="header-modal">
                <div class="title">Vui lòng ký xác nhận</div>
                <div class="close-btn-container" @click="onCloseSignModal">
                  <img src="@/assets/images/video-call/close-modal.svg" />
                </div>
              </div>
              <div class="sign-area">
                <VueSignatureCanvas
                  ref="handWriteAdmin"
                  :canvasProps="{
                    class: 'full-area',
                  }"
                />
              </div>
              <div class="sign-info">
                Các thông tin và chữ ký của bạn sẽ được tự động điền vào <br />
                <strong
                  >Hợp đồng cung cấp và sử dụng dịch vụ thông tin di động trả
                  trước</strong
                >
              </div>
              <div class="sign-btn-controls">
                <button class="sign-btn" @click="onResign">Ký lại</button>
                <button class="sign-btn" @click="onSendSignature">Gửi</button>
              </div>
            </div>
          </div>
          <div
            v-if="callStep == steps.VIEW_CONTRACT"
            class="contract-container"
          >
            <div class="contract-modal">
              <div class="header-modal">
                <div class="title">Hợp đồng của bạn</div>
                <div class="close-btn-container" @click="onCloseSignViewModal">
                  <img src="@/assets/images/video-call/close-modal.svg" />
                </div>
              </div>
              <div class="your-sign-show">
                <img
                  :src="'data:image/jpg;base64,' + formBase64"
                  class="video-image"
                />
              </div>
              <div class="sign-view-controls">
                <button
                  class="close-sign-view-btn"
                  @click="onCloseSignViewModal"
                >
                  Đóng
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="callStep == steps.SUCCESS && isShowModal"
            class="success-container"
          >
            <div class="success-modal">
              <div class="header-modal">
                <div class="close-btn-container" @click="onClosePopup">
                  <img src="@/assets/images/video-call/close-modal.svg" />
                </div>
              </div>
              <div class="icon-desc">
                <img src="@/assets/images/video-call/tick.svg" />
              </div>
              <!--              <div class="text-info-modal">Đăng ký thành công!</div>-->
              <div class="desc-info-modal">
                Hồ sơ của Bạn đã được xử lý thành công và sẵn sàng sử dụng. Nếu
                cần hỗ trợ thêm, Bạn hãy liên hệ iTel nhé!
                <span class="heart-icon">&#10084;</span>
              </div>
              <div class="sign-view-controls">
                <button class="close-sign-view-btn" @click="onComplete()">
                  Hoàn thành
                </button>
              </div>
            </div>
          </div>
          <!--          <div v-if="callStep == steps.REFUSE && isShowModal" class="success-container">-->
          <!--            <div class="success-modal">-->
          <!--              <div class="header-modal">-->
          <!--                <div class="close-btn-container" @click="onClosePopup">-->
          <!--                  <img src="@/assets/images/video-call/close-modal.svg"/>-->
          <!--                </div>-->
          <!--              </div>-->
          <!--              <div class="icon-desc">-->
          <!--                <img src="@/assets/images/video-call/tick.svg"/>-->
          <!--              </div>-->
          <!--              <div class="text-info-modal">Đăng ký thất bại!</div>-->
          <!--              <div class="desc-info-modal">-->
          <!--                Đăng ký thất bại do hồ sơ chưa hợp lệ. Vui lòng kiểm tra và thực hiện lại Bạn nhé!-->
          <!--                <span class="heart-icon">&#10084;</span>-->
          <!--              </div>-->
          <!--              <div class="sign-view-controls">-->
          <!--                <button class="close-sign-view-btn" @click="onComplete()">-->
          <!--                  Đóng-->
          <!--                </button>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div
            v-if="callStep == steps.FIRST_FAIL && isShowModal"
            class="fail-1-container"
          >
            <div class="success-modal">
              <div class="header-modal">
                <div class="close-btn-container" @click="onCloseCallAgain">
                  <img src="@/assets/images/video-call/close-modal.svg" />
                </div>
              </div>
              <div class="icon-desc">
                <img src="@/assets/images/video-call/fail.png" />
              </div>
              <div class="fail-info-text text-justify">
                Xin lỗi khi chưa thể kết nối Bạn tới các Tổng đài viên. Bạn vui
                lòng bấm nút <b class="text-primary">Quay Video</b> để được hỗ
                trợ hoàn tất quá trình Đăng ký/Cập nhật thông tin.
              </div>
              <div class="sign-btn-controls">
                <!--                <button class="sign-btn" @click="onCallAgainClick">-->
                <!--                  Gọi lại-->
                <!--                </button>-->
                <button class="sign-close-btn" @click="goToSendVideo">
                  Quay video
                </button>
              </div>
            </div>
          </div>
          <div
            v-if="callStep == steps.SECOND_FAIL && isShowModal"
            class="fail-1-container"
          >
            <div class="success-modal">
              <div class="header-modal">
                <div class="close-btn-container" @click="onCloseCallAgain">
                  <img src="@/assets/images/video-call/close-modal.svg" />
                </div>
              </div>
              <div class="icon-desc">
                <img src="@/assets/images/video-call/fail.png" />
              </div>
              <div class="fail-info-text">
                Rất tiếc! Tạm thời các Giao dịch viên đều đang bận. Bạn vui
                lòng:<br />Không thoát ứng dụng và liên hệ lại sau ít phút
              </div>
              <div class="sign-btn-controls">
                <!--                <button class="sign-btn" @click="onCallAgainClick">-->
                <!--                  Gọi lại-->
                <!--                </button>-->
                <button class="sign-close-btn" @click="onCloseCallAgain">
                  Đóng
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--        <div v-if="callStep == steps.CONNECTED" class="confirm-container">-->
        <!--          <button class="sign-confirm-btn" @click="onConfirmBtnClick">-->
        <!--            Ký xác nhận-->
        <!--          </button>-->
        <!--        </div>-->
      </div>
    </div>
    <div class="main-body" v-else>
      <div class="bg-white p-2 rounded end-call-modal">
        <h4 class="font-medium-5 text-center mt-1">
          Chúc bạn một ngày tốt lành! ❤️❤️
        </h4>
        <div class="d-flex justify-content-center">
          <img src="@/assets/images/video-call/end-call-img.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSignatureCanvas from "vue-signature-canvas";
import Twilio, {
  createLocalAudioTrack,
  createLocalVideoTrack,
} from "twilio-video";
import { jwt } from "twilio";
import io from "socket.io-client";
import EventBus from "@/global-components";
import moment from "moment";
import axios from "axios";
import { $ctx } from "@themeConfig";

const AccessToken = jwt.AccessToken;
const VideoGrant = AccessToken.VideoGrant;

let videoCallInterval = null;
let waittingCallInterval = null;
let VIDEO_CALL_WAITTING_TIME = 60;

const CALL_STEP = {
  VIEW_INSTRUCTIONS: 0,
  CONNECTING: 1,
  FIRST_FAIL: 2,
  SECOND_FAIL: 3,
  CONNECTED: 4,
  CONFIRM: 5,
  VIEW_CONTRACT: 6,
  SUCCESS: 7,
  // REFUSE: 8,
};

export default {
  name: "VideoCall",
  components: {
    VueSignatureCanvas,
  },
  data() {
    return {
      serial: "",
      prevRoute: null,
      callStep: CALL_STEP.VIEW_INSTRUCTIONS,
      steps: CALL_STEP,
      isShowModal: false,
      status: "",
      loading: false,
      data: {},
      localTrack: [],
      remoteTrack: [],
      activeRoom: "",
      previewTracks: "",
      identity: "",
      roomName: null,
      captureScreenSrc: "",
      groupImage: {
        frontCMND: "",
        backCMND: "",
        profilePicture: "",
      },
      cardInfo: {
        name: "",
        idCard: "",
        dueDate: "",
        dob: "",
        address: "",
        hometown: "",
        gender: "",
        issueDate: "",
        issueAt: "",
      },
      username: "",
      tellerId: "",
      btnSignVisible: false,
      step: 1,
      signData: "",
      formBase64: "",
      callTimes: 0,
      timestamp: moment(),
      isRequiredReload: false,
      cameraDeviceId: "",
      isCameraOn: true,
      isMicOn: true,
      listCamera: [],
      socketLink: $ctx.API_URL.includes("dev")
        ? "https://socket.itel.dev/"
        : "https://socket.itel.vn/",
      isEndCall: false,
      logInfo: {
        isLogged: false,
        startTime: null,
        connectTime: null,
        missType: null,
        room_id: null,
      },
      callId: "",
      isNotPermission: false,
    };
  },
  props: {},
  watch: {
    remoteTrack(value) {
      let previewContainer = document.getElementById("remoteTrack");
      previewContainer.innerHTML = "";
      value.forEach((el) => {
        const child = el.attach() ? el.attach() : null;
        if (child) {
          previewContainer.appendChild(child);
        }
      });
    },
    callStep() {
      setTimeout(() => {
        this.updateLocalTrackElement(this.localTrack);
      }, 100);
    },
    localTrack(value) {
      this.updateLocalTrackElement(value);
    },
    cameraDeviceId() {
      this.updateVideoDevice();
    },
  },
  created() {
    // EventBus.$on('show_room', (room_name) => {
    //     this.createChat(room_name);
    // })
    window.addEventListener("beforeunload", this.handleBeforeUnload);
  },
  beforeRouteEnter(to, from, next) {
    // console.log('to', to)
    // console.log('from', from)
    next((vm) => {
      vm.prevRoute = from;
    });
  },
  mounted() {
    const intervalFooter = setInterval(() => {
      if (window.hiddenFooter) {
        window.hideChatBot(true);
        window.hiddenFooter(false);
        clearInterval(intervalFooter);
      }
    }, 1000);
    this.onCheckPhone();
    //'https://nhan.tech:5000'
    // 192.168.2.36
    this.socket = io(this.socketLink, { transports: ["polling", "websocket"] });
    // this.socket = io("https://192.168.2.36:5000/", { transports: ["websocket"] });
    if (this.$route.params.key) {
      if (
        (parseInt(this.$route.params.phone.slice(-3)) + 112) * 2 !=
        this.$route.params.key
      ) {
        this.isEndCall = true;
      }
    }
    if (this.$route.name === "video-call-dktt") {
      this.phone = this.$route.params.phone.replace("0", "84");
      this.serial = this.$route.params.seri;
      // console.log("this.phone", this.phone, this.serial);
    }
    if (this.$route.name === "video-call-dktt-non-key") {
      this.phone = this.$route.params.phone.replace("0", "84");
      this.serial = this.$route.params.seri;
      // console.log("this.phone", this.phone, this.serial);
    }
    if (this.$route.name === "video-call-cntt") {
      this.phone = this.$route.params.phone.replace("0", "84");
      // console.log("this.phone", this.phone, this.serial);
    }
    if (this.$route.name === "video-call-cntt-non-key") {
      this.phone = this.$route.params.phone.replace("0", "84");
      // console.log("this.phone", this.phone, this.serial);
    }
    // this.getCallTimes()
    this.socket.on("connecting-free-teller", () => {
      this.clearAllIntervals();
      this.handleWaittingGDVAcceptCall();
      this.status = "waiting-accept";
      this.callStep = this.steps.CONNECTING;
    });

    this.socket.on("start-call", (data) => {
      this.clearAllIntervals();
      this.status = "call";
      this.tellerId = data.id;
      this.logInfo.room_id = data.roomId;
      let createChatInterval = setInterval(() => {
        if (this.localTrack && this.localTrack.length > 1) {
          this.createChat(data.roomId, data.token);
          clearInterval(createChatInterval);
        }
      }, 1000);
    });

    this.socket.on("admin-stop-call", async (data) => {
      this.isEndCall = true;
      this.clearAllIntervals();
      this.status = "";
      this.logInfo.missType = "2";
      await this.logUpdateStopCall();
      this.removeCall();
      this.callStep = this.steps.VIEW_INSTRUCTIONS;
    });

    this.socket.on("admin-refuse-call", (data) => {
      this.clearAllIntervals();
      this.status = "refuse";
      this.logInfo.missType = "2";
      this.removeCall();
      if (this.callTimes == 1) {
        this.callStep = this.steps.FIRST_FAIL;
      } else {
        this.callStep = this.steps.SECOND_FAIL;
      }
      this.isRequiredReload = true;
      window.location.reload();
    });

    // this.socket.on('admin-request-sign', data => {
    //     this.cardInfo = data.cardInfo
    //     this.groupImage = data.groupImage
    //     this.btnSignVisible = true
    // })

    this.socket.on("admin-request-client-sign", (data) => {
      this.callStep = this.steps.CONFIRM;
    });
    this.socket.on("admin-start-recording", (data) => {
      this.handleAdminStartRecording();
    });

    this.socket.on("no-free-teller", (data) => {
      this.status = "no-teller";
      this.callStep = this.steps.FIRST_FAIL;
      if (this.logInfo.missType != 2) {
        this.logInfo.missType = "2";
        this.logUpdateStopCall();
        this.isShowModal = true;
      }
      // this.removeCall()
    });
    // this.socket.on('admin-reject-client-registration', data => {
    //   this.callStep = this.steps.REFUSE
    //   this.isShowModal = true
    // })
    this.socket.on("register-result", (data) => {
      if (data?.isSuccess) {
        this.isShowModal = true;
        this.callStep = this.steps.SUCCESS;
        this.$toast.success("Đăng ký thành công!", {
          icon: true,
          closeButton: "button",
        });
      } else {
        this.$toast.error("Đăng ký thất bại!", {
          icon: true,
          closeButton: "button",
        });
      }
    });

    this.socket.on("admin-send-registration-form", (data) => {
      this.formBase64 = data.base64;
      this.step = 2;
      // this.$bvModal.show('modal-sign')
      this.callStep = this.steps.VIEW_CONTRACT;
    });

    this.getCallId();
  },
  beforeDestroy() {
    window.hiddenFooter(true);
    window.hideChatBot(false);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
    this.clearAllIntervals();
    if (this.localTrack) {
      this.localTrack.forEach((track) => track.stop());
    }
  },
  methods: {
    handleAdminStartRecording() {
      this.isShowModal = true;
      this.callStep = this.steps.FIRST_FAIL;
      this.socket.emit("client-stop-call", {
        telNumber: this.phone,
        serial: this.serial,
        SID: this.activeRoom.sid,
      });
    },
    getCallId() {
      if (this.prevRoute.name === "DKTT") {
        this.callId = localStorage.getItem("callId");
      } else {
        this.callId = this.$route.params.phone;
      }
    },
    onCheckPhone() {
      axios
        .get($ctx.API_CRM_URL + "/check-recall/" + this.$route.params.phone)
        .then((res) => {
          if (res.data.code !== 200) {
            this.isEndCall = true;
          }
        })
        .catch((error) => {
          this.isEndCall = true;
          // console.log(error);
        });
    },
    // getCallTimes() {
    //   axios
    //     .post($ctx.API_CRM_URL + "/get-list-his-call-detail/" + this.phone, {
    //       page: 1,
    //       pageSize: 500000,
    //     })
    //     .then((res) => {
    //       this.callTimes = res.data.totalRecords;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },
    onToggleMic() {
      if (this.activeRoom) {
        this.isMicOn = !this.isMicOn;
        const localParticipant = this.activeRoom.localParticipant;

        // track show on gdv page
        const tracks = Array.from(localParticipant.audioTracks.values());
        tracks.forEach((audioTrack) => {
          if (this.isMicOn) {
            audioTrack.track.enable();
          } else {
            audioTrack.track.disable();
          }
        });
      }
    },
    onToggleCamera() {
      if (this.activeRoom) {
        this.isCameraOn = !this.isCameraOn;
        const localParticipant = this.activeRoom.localParticipant;

        // track show on gdv page
        const tracks = Array.from(localParticipant.videoTracks.values());
        tracks.forEach((videoTrack) => {
          if (this.isCameraOn) {
            videoTrack.track.enable();
            //gắn lại video track vào dom
            const videoElement = document.createElement("video");
            document.getElementById("localTrack").appendChild(videoElement);
            videoTrack.track.attach(videoElement);
          } else {
            videoTrack.track.disable();
            //xóa video track khỏi dom
            const mediaElements = videoTrack.track.detach();
            mediaElements.forEach((mediaElement) => mediaElement.remove());
          }
        });

        // local track
        this.localTrack.forEach((videoTrack) => {
          if (this.isCameraOn) {
            videoTrack.enable();
          } else {
            videoTrack.disable();
          }
        });
      }
    },
    detachTracks(tracks) {
      tracks.forEach(function (track) {
        if (track) {
          track.detach().forEach(function (detachedElement) {
            detachedElement.remove();
          });
        }
      });
    },
    attachTracks(tracks, container) {
      tracks.forEach(function (track) {
        if (track) {
          container.appendChild(track.attach());
        }
      });
    },
    stopTracks(tracks) {
      tracks.forEach(function (track) {
        if (track) {
          track.stop();
        }
      });
    },
    updateVideoDevice() {
      if (this.activeRoom) {
        try {
          this.isCameraOn = true;
          const localParticipant = this.activeRoom.localParticipant;
          const Vue = this;
          const optionsLocalTrack = {};
          if (this.cameraDeviceId) {
            optionsLocalTrack.deviceId = { exact: this.cameraDeviceId };
          }
          const tracks = Array.from(localParticipant.videoTracks.values()).map(
            function (trackPublication) {
              return trackPublication.track;
            }
          );
          localParticipant.unpublishTracks(tracks);
          this.detachTracks(tracks);
          this.stopTracks(tracks);
          createLocalVideoTrack(optionsLocalTrack).then(function (
            localVideoTrack
          ) {
            localParticipant.publishTrack(localVideoTrack);
            const previewContainer = document.getElementById("localTrack");
            Vue.attachTracks([localVideoTrack], previewContainer);
          });

          // const VideoTracks = Array.from(localParticipant.videoTracks);
          // if (VideoTracks.length > 0) {
          //   localParticipant.unpublishTracks(VideoTracks.map((el) => el.track));
          // }
          // const AudioTracks = Array.from(localParticipant.audioTracks);
          // if (AudioTracks.length > 0) {
          //   localParticipant.unpublishTracks(AudioTracks.map((el) => el.track));
          // }
          // VideoTracks.forEach((videoTrack) => {
          //   videoTrack.track.stop();
          //   const mediaElements = videoTrack.track.detach();
          //   mediaElements.forEach((mediaElement) => mediaElement.remove());
          // });
          // this.localTrack.forEach((videoTrack) => {
          //   videoTrack.stop();
          //   const mediaElements = videoTrack.detach();
          //   mediaElements.forEach((mediaElement) => mediaElement.remove());
          // });

          //Note: stop local track DongNH 2021-09-29
          // Description: stop local track when change camera
          // if (this.localTrack) {
          //   this.localTrack.forEach((track) => track.stop());
          // }
          // this.localTrack = [];
          // createLocalAudioTrack()
          //   .then((track) => {
          //     this.localTrack.push(track);
          //   })
          //   .catch((err) => {
          //     console.log("err createLocalAudioTrack", err);
          //   });
          // createLocalVideoTrack(optionsLocalTrack)
          //   .then((track) => {
          //     localParticipant.publishTrack(track);
          //     this.localTrack.push(track);
          //   })
          //   .catch((err) => {
          //     console.log("err createLocalVideoTrack", err);
          //     this.$toast.error("Không thể thay đổi camera!" + err.message, {
          //       icon: true,
          //       closeButton: "button",
          //     });
          //   });
        } catch (err) {
          this.$toast.error("Không thể thay đổi camera!", {
            icon: true,
            closeButton: "button",
          });
        }
      }
    },
    async onChangeCamera() {
      if (this.listCamera.length === 0) {
        this.listCamera = await navigator.mediaDevices
          .enumerateDevices()
          .then((data) => {
            return this.gotDevices(data);
          });
        this.listCamera = this.listCamera.filter(
          (_, idx) => idx === 0 || idx === this.listCamera.length - 1
        );
      }

      if (!this.listCamera || this.listCamera.length < 1) {
        return;
      }

      if (!this.cameraDeviceId) {
        this.cameraDeviceId = this.listCamera[this.listCamera.length - 1];
      } else {
        // if(this.cameraDeviceId !== this.listCamera[this.listCamera.length - 1]){
        //   this.cameraDeviceId = this.listCamera[this.listCamera.findIndex(el => el == this.cameraDeviceId) + 1]
        // } else {
        //   this.cameraDeviceId = this.listCamera[0]
        // }
        const currentIdx = this.listCamera.findIndex(
          (el) => el === this.cameraDeviceId
        );
        if (currentIdx === 1) {
          this.cameraDeviceId = this.listCamera[0];
        } else {
          this.cameraDeviceId = this.listCamera[1];
        }
      }
    },
    gotDevices(mediaDevices) {
      let cameraList = [];
      mediaDevices.forEach((mediaDevice) => {
        if (mediaDevice.kind === "videoinput") {
          cameraList.push(mediaDevice.deviceId);
        }
      });

      return cameraList;
    },
    clearAllIntervals() {
      if (videoCallInterval) {
        clearInterval(videoCallInterval);
      }
      if (waittingCallInterval) {
        clearInterval(waittingCallInterval);
      }
    },
    onComplete() {
      this.isShowModal = false;
      // window.location.reload();
    },

    // async checkPermission() {
    //   let isPermissionMic = false;
    //   let isPermissionCam = false;
    //   await navigator.permissions
    //     .query({ name: "microphone" })
    //     .then(function (result) {
    //       console.log("mic", result.state);
    //       if (result.state == "granted") {
    //         isPermissionMic = true;
    //       } else if (result.state == "denied") {
    //         isPermissionMic = false;
    //       }
    //     });
    //   await navigator.permissions
    //     .query({ name: "camera" })
    //     .then(function (result) {
    //       console.log("cam", result.state);
    //       if (result.state == "granted") {
    //         isPermissionCam = true;
    //       } else if (result.state == "denied") {
    //         isPermissionCam = false;
    //       }
    //     });
    //   console.log("isPermissionMic", isPermissionMic);
    //   console.log("isPermissionCam", isPermissionCam);
    //
    //   if (!isPermissionMic || !isPermissionCam) {
    //     this.isNotPermission = true;
    //   }
    // },

    async onVideoCallClick() {
      //  await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
      //      .catch(error => {
      //        console.log(error)
      //      })
      // await this.checkPermission()
      //  console.log('this.isNotPermission', this.isNotPermission)
      //  if (this.isNotPermission) {
      //    this.$swal({
      //      title: "Thông báo",
      //      text: "Bạn chưa cấp quyền truy cập Micro và Camera",
      //      confirmButtonText: "Đóng",
      //    });
      //    this.logInfo.startTime = moment().format('YYYY-MM-DD HH:mm:ss')
      //    this.logInfo.missType = '1'
      //    await this.logUpdateStopCall()
      //  }
      //  else {
      this.logInfo.startTime = moment().format("YYYY-MM-DD HH:mm:ss");
      this.callStep = this.steps.CONNECTING;
      this.startChat();
      // }
    },
    onConfirmBtnClick() {
      this.callStep = this.steps.CONFIRM;
    },
    onCloseSignModal() {
      this.callStep = this.steps.CONNECTED;
    },
    onSendSignature() {
      this.confirm();
    },
    onCloseSignViewModal() {
      this.callStep = this.steps.CONNECTED;
    },
    onCallAgainClick() {
      this.callStep = this.steps.CONNECTING;
      this.isShowModal = false;
      this.startChat();
    },
    onClosePopup() {
      this.isShowModal = false;
    },
    onCloseCallAgain() {
      this.isShowModal = false;
      this.callStep = this.steps.VIEW_INSTRUCTIONS;
      window.location.reload();
    },
    goToSendVideo() {
      if (this.localTrack) {
        this.localTrack.forEach((track) => track.stop());
      }
      if (this.$route.params.key) {
        this.$router.push({
          name: "send-video-vc",
          params: { phone: this.phone, key: this.$route.params.key },
        });
      } else {
        this.$router.push({
          name: "send-video-vc-non-key",
          params: { phone: this.phone },
        });
      }
    },
    onResign() {
      this.$refs.handWriteAdmin.clear();
    },
    updateLocalTrackElement(tracks) {
      this.localTrack.forEach((videoTrack) => {
        const mediaElements = videoTrack.detach();
        mediaElements.forEach((mediaElement) => mediaElement.remove());
      });

      let previewContainer = document.getElementById("localTrack");
      previewContainer.innerHTML = "";
      tracks.forEach((el) => {
        const child = el.attach() ? el.attach() : null;
        if (child) {
          previewContainer.appendChild(child);
        }
      });
    },
    sendData() {
      this.socket.emit("client-registration-confirmation", {
        id: this.tellerId,
      });

      this.callStep = this.steps.CONNECTED;
      // this.$bvModal.hide('modal-sign')
    },
    confirm() {
      if (this.$refs.handWriteAdmin.isEmpty()) {
        this.$toast.error("Vui lòng ký xác nhận!", {
          icon: true,
          closeButton: "button",
        });
        return;
      }
      this.signData = this.$refs.handWriteAdmin.toDataURL();
      this.socket.emit("client-send-sign", {
        id: this.tellerId,
        signData: this.signData
          .replace("data:image/jpeg;base64,", "")
          .replace("data:image/png;base64,", "")
          .replace("data:image/jpg;base64,", ""),
      });

      this.callStep = this.steps.CONNECTED;
    },
    clearSign() {
      this.$refs.handWrite.clear();
    },
    onBtnSignClick() {
      // this.btnSignVisible = false
      this.step = 1;
      this.callStep = this.steps.CONFIRM;
    },
    // Generate access token
    async getAccessToken() {
      // return await axios.get(`http://192.168.2.17:3000/token?identity=${this.username}`);
      const identity = this.username;
      let token = new AccessToken(
        "AC1f22f13ea5a9360d36cb002a929295b4",
        "SK75c738f23c70bc16d217699f504ad5d1",
        "90b40c48fd9ed1ecb1c731a243eef965"
      );
      token.identity = identity;
      let grant = new VideoGrant();
      token.addGrant(grant);

      return token.toJwt();
    },

    // Trigger log events
    dispatchLog(message) {
      EventBus.$emit("new_log", message);
    },

    handleBeforeUnload(event) {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }
      if (this.isRequiredReload) {
        return;
      }
      // return (event.returnValue =
      //     'Are you sure you want to exit?');
    },
    // Leave Room.
    leaveRoomIfJoined() {
      if (this.activeRoom) {
        this.activeRoom.disconnect();
      }
    },

    // captureScreen(imageType) {
    //   let previewContainer = document.getElementById("remoteTrack");
    //   console.log("previewContainer", previewContainer.children);
    //
    //   if (previewContainer.children && previewContainer.children.length > 0) {
    //     let canvas = document.createElement("canvas");
    //     let video = previewContainer.children[0];
    //     if (video.tagName.toLowerCase() != "video") {
    //       for (let i = 1; i < previewContainer.children.length; i++) {
    //         if (previewContainer.children[i].tagName.toLowerCase() == "video") {
    //           video = previewContainer.children[i];
    //         }
    //       }
    //     }
    //     if (video && video.tagName.toLowerCase() == "video") {
    //       canvas.width = video.clientWidth;
    //       canvas.height = video.clientHeight;
    //
    //       let ctx = canvas.getContext("2d");
    //       ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
    //
    //       let image = canvas.toDataURL("image/jpeg");
    //
    //       this.groupImage = { ...this.groupImage, [imageType]: image };
    //     }
    //   }
    // },
    startChat() {
      this.isShowModal = false;
      this.callTimes = this.callTimes + 1;

      // if local preview is not active, create it
      if (!this.localTrack || this.localTrack.length == 0) {
        const optionsLocalTrack = {};
        if (this.cameraDeviceId) {
          optionsLocalTrack.deviceId = { exact: this.cameraDeviceId };
        }
        createLocalAudioTrack()
          .then((track) => {
            this.localTrack.push(track);
          })
          .catch((err) => {
            // console.log("err createLocalAudioTrack", err);
          });
        createLocalVideoTrack()
          .then((track) => {
            this.localTrack.push(track);
          })
          .catch((err) => {
            // console.log("err createLocalVideoTrack", err);
          });
      }

      this.socket.emit("client-start-call", {
        telNumber: this.phone,
        serial: this.serial,
      });
      this.timestamp = moment();
      videoCallInterval = setInterval(async () => {
        if (
          this.status !== "call" &&
          moment().diff(this.timestamp, "seconds") < VIDEO_CALL_WAITTING_TIME
        ) {
          // this.socket.emit("client-start-call", {
          //   telNumber: this.phone,
          //   serial: this.serial,
          // });
        } else {
          if (this.status == "call" || this.status == "no-teller") {
            this.clearAllIntervals();
            return;
          }
          if (this.callTimes == 1) {
            this.callStep = this.steps.FIRST_FAIL;
          } else {
            this.callStep = this.steps.SECOND_FAIL;
          }
          this.logInfo.missType = "2";
          await this.logUpdateStopCall();
          this.stopCall();
        }
      }, [5000]);
    },
    handleWaittingGDVAcceptCall() {
      this.timestamp = moment();
      waittingCallInterval = setInterval(async () => {
        if (
          moment().diff(this.timestamp, "seconds") < VIDEO_CALL_WAITTING_TIME
        ) {
          if (this.status == "call" || this.status == "no-teller") {
            this.clearAllIntervals();
          }
        } else {
          if (this.callTimes == 1) {
            this.callStep = this.steps.FIRST_FAIL;
          } else {
            this.callStep = this.steps.SECOND_FAIL;
          }
          this.logInfo.missType = "2";
          await this.logUpdateStopCall();
          this.stopCall();
        }
      }, 1000);
    },
    async clientStopCall() {
      // console.log("clientStopCall");
      this.logInfo.missType = "1";
      await this.logUpdateStopCall();
      this.stopCall();
    },
    stopCall() {
      this.isShowModal = true;
      this.clearAllIntervals();
      this.status = "";
      this.socket.emit("client-stop-call", {
        telNumber: this.phone,
        serial: this.serial,
        SID: this.activeRoom.sid,
      });
      this.removeCall();
    },
    async logUpdateStopCall() {
      if (this.logInfo.isLogged) return;
      this.logInfo.isLogged = true;
      await axios.post(
        $ctx.API_CRM_URL + "/update-stop-call-time/" + this.callId,
        {
          startCall: this.logInfo.startTime,
          connected: this.logInfo.connectTime,
          type: this.logInfo.missType,
          permission: this.isNotPermission ? "0" : "1",
          stopCall: moment().format("YYYY-MM-DD HH:mm:ss"),
          room_id: this.logInfo.room_id,
        }
      );
    },

    removeCall() {
      this.localTrack.forEach((videoTrack) => {
        const mediaElements = videoTrack.detach();
        videoTrack.stop();
        mediaElements.forEach((mediaElement) => mediaElement.remove());
      });
      // this.localTrack = [];

      this.leaveRoomIfJoined();
      this.remoteTrack = [];
      this.localTrack = [];
      this.activeRoom = null;

      this.isRequiredReload = true;
      if (
        this.callStep != this.steps.FIRST_FAIL &&
        this.callStep != this.steps.SECOND_FAIL
      ) {
        window.location.reload();
      }
    },

    async logTwilioConnect(phone, room, data, isSuccess, localTrack) {
      await axios.post($ctx.API_CRM_URL + "/twilio-log", {
        phone: phone,
        room: room,
        data: isSuccess ? data : data.toString(),
        localTrack: localTrack,
      });
    },

    // Create a new chat
    createChat(room_name, token) {
      // console.log("create chat");
      this.username = (Math.random() * 1000).toString();
      this.loading = true;
      const VueThis = this;
      VueThis.logInfo.connectTime = moment().format("YYYY-MM-DD HH:mm:ss");
      VueThis.roomName = null;
      let connectOptions = {
        name: room_name,
        // logLevel: 'debug',
        audio: true,
        video: {
          frameRate: { min: 15, ideal: 30, max: 60 },
          height: { min: 480, ideal: 480, max: 1080 },
          width: { min: 640, ideal: 640, max: 1920 },
          preferredVideoCodecs: [{ codec: "H264", simulcast: true }],
        },
        tracks: VueThis.localTrack,
        maxVideoBitrate: 2000000,
        maxAudioBitrate: 16000,
      };
      // before a user enters a new room,
      // disconnect the user from they joined already
      this.leaveRoomIfJoined();

      // remove any remote track when joining a new room
      document.getElementById("remoteTrack").innerHTML = "";

      // if (this.localTrack) {
      //   this.localTrack.forEach((videoTrack) => {
      //     const mediaElements = videoTrack.detach();
      //     videoTrack.stop();
      //     mediaElements.forEach((mediaElement) => mediaElement.remove());
      //   });
      //   createLocalVideoTrack().then((localVideoTrack) => {
      //     this.localTrack = [localVideoTrack];
      //   });
      // }

      // if local preview is not active, create it
      // if (!this.localTrack || this.localTrack.length == 0) {
      //   const optionsLocalTrack = {};
      //   if (this.cameraDeviceId) {
      //     optionsLocalTrack.deviceId = { exact: this.cameraDeviceId };
      //   }
      //   createLocalVideoTrack().then((track) => {
      //     this.localTrack = [track];
      //   });
      // }

      Twilio.connect(token, connectOptions)
        .then(function (room) {
          // console.log('Successfully joined a Room: ', room);
          VueThis.dispatchLog("Successfully joined a Room: " + room_name);

          // set active toom
          VueThis.activeRoom = room;
          VueThis.roomName = room_name;
          VueThis.loading = false;
          VueThis.logTwilioConnect(
            VueThis.phone,
            room_name,
            room,
            true,
            VueThis.localTrack
          );
          VueThis.callStep = VueThis.steps.CONNECTED;
          room.participants.forEach(function (participant) {
            let previewContainer = document.getElementById("remoteTrack");
            participant.on("trackSubscribed", (track) => {
              previewContainer.appendChild(track.attach());
            });
          });
        })
        .catch((err) => {
          // console.log("err ", err);
          VueThis.logTwilioConnect(
            VueThis.phone,
            room_name,
            err,
            false,
            VueThis.localTrack
          );
          VueThis.isShowModal = true;
          VueThis.callStep = VueThis.steps.FIRST_FAIL;
        });
    },
  },
};
</script>
<style lang="scss">
#remoteTrack {
  width: 100%;

  video {
    width: 100%;
  }

  &:has(video) {
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
  }
}

#localTrack {
  width: 100%;

  video {
    width: 100%;
  }

  &:has(video) {
    height: 100%;
    background: #fff;
    border-radius: 10px;
    display: flex;
    align-items: center;
  }
}
</style>
<style lang="scss" scoped>
.img-video-call {
  width: 80%;
}

.end-call-modal {
  border: 2px solid #ffbefe;
}

.full-area {
  width: 100% !important;
  height: 100% !important;
}

@keyframes d1 {
  0% {
    transform: scale3d(1, 1, 1);
  }

  70% {
    transform: scale3d(2, 2, 2);
  }

  100% {
    transform: scale3d(1, 1, 1);
  }
}

@keyframes d2 {
  0% {
    transform: scale3d(1.5, 1.5, 1.5);
  }

  30% {
    transform: scale3d(2, 2, 2);
  }

  50% {
    transform: scale3d(1.5, 1.5, 1.5);
  }

  80% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(1.5, 1.5, 1.5);
  }
}

@keyframes d3 {
  0% {
    transform: scale3d(2, 2, 2);
  }

  60% {
    transform: scale3d(1, 1, 1);
  }

  100% {
    transform: scale3d(2, 2, 2);
  }
}

.vc-page-bg {
  background: white;
  min-height: 85vh;
  display: flex;
  flex-direction: column;
  min-width: 350px;

  h2 {
    color: black;
  }

  .main-title {
    padding: 33px 38px 25px;
    color: #393939;
    font-feature-settings: "clig" off, "liga" off;
    font-family: Roboto;
    font-size: 23px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    text-transform: uppercase;
  }

  .main-body {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid #d9d9d9;

    .vc-modal {
      width: min-content;
      border-radius: 13.778px;
      background: #fff;
      box-shadow: 0px 6.88889px 68.88891px 0px rgba(0, 0, 0, 0.1);
      padding: 20px 40px 50px;

      .wall-bg {
        width: 520px;
        height: 181px;
        border-radius: 14px;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .detail-instructions {
        margin-top: 21px;
        margin-bottom: 21px;
        padding-inline: 10px;

        .title-instruction {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          font-family: Roboto;
          font-size: 21px;
          font-style: normal;
          font-weight: 700;
          line-height: 22px;
          color: #151522;
          margin-bottom: 20px;
        }

        ul {
          padding-left: 25px;

          li {
            color: #151522;
            font-family: Roboto;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            list-style-type: disc;
          }
        }
      }

      .desc-instructions {
        column-gap: 10px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        padding: 0 91px;

        .desc-detail-item {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: start;

          .desc-face {
            width: 73px;
            height: 73px;
            border-radius: 73px;
            display: flex;
            justify-content: center;
            align-items: center;

            .img {
              width: 100%;
              height: 100%;
            }
          }

          .desc-x {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-top: 10px;
            margin-bottom: 10px;

            img {
              width: 25px;
              height: 25px;
            }
          }

          .desc-text {
            color: #373737;
            text-align: center;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
            width: 100%;
            white-space: pre-wrap;
          }
        }
      }
    }

    .vc-show {
      width: 632px;
      display: flex;
      flex-direction: column;

      .main-video {
        width: 100%;
        height: 730px;
        border-radius: 13.778px;
        // background-image: url("../assets/images/video-call/video-img.png");
        // background-size: 100% 100%;
        background: black;
        position: relative;

        .local-track-container__before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          // z-index: -1;
        }

        .fade-bg {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 2;
          background: linear-gradient(
            rgb(255 255 255 / 2%),
            rgb(255 255 255 / 28%)
          );
        }

        .connecting {
          position: absolute;
          top: 52px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 3;
          display: flex;
          flex-direction: column;
          align-items: center;

          .caller-avatar {
            width: 180px;
            height: 180px;
            border-radius: 180px;
            overflow: hidden;

            img {
              width: 100%;
              height: 100%;
            }
          }

          .connecting-text {
            margin-top: 39px;
            color: #fff;
            font-family: Inter;
            font-size: 28px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          .dots {
            display: flex;
            align-items: center;
            margin-top: 20px;
            column-gap: 20px;

            .dot-1 {
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background: white;
              animation: d3 1400ms;
              animation-iteration-count: infinite;
            }

            .dot-2 {
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background: white;
              animation: d2 1400ms;
              animation-iteration-count: infinite;
            }

            .dot-3 {
              height: 4px;
              width: 4px;
              border-radius: 50%;
              background: white;
              animation: 1400ms d1;
              animation-iteration-count: infinite;
            }
          }
        }

        .connected {
          z-index: 3;
          position: absolute;
          right: 15px;
          top: 11px;
          width: 140px;
          height: 165px;
          border-radius: 10px;
          background-image: url("../assets/images/video-call/connected-user.png");
          background-size: 100% 100%;

          .local-track-container {
            position: relative;
            width: 140px;
            height: 165px;
          }

          video {
            width: 100%;
            height: 100%;
          }
        }

        .bottom-controls {
          position: absolute;
          bottom: 43px;
          left: 50%;
          transform: translateX(-50%);
          border-radius: 20px;
          background: #1d1d1d9d;
          display: flex;
          align-items: center;
          padding: 13px 0;
          z-index: 3;

          .control-item {
            height: 32px;
            margin-inline: 18px;
            cursor: pointer;

            img {
              height: 100%;
            }

            &.exit-call {
              width: 42px;
              height: 42px;
              border-radius: 50%;
              background: #ed1f24;
              display: flex;
              justify-content: center;
              align-items: center;

              img {
                width: 17px;
                height: 17px;
              }
            }

            &:disabled {
              opacity: 0.5;
              cursor: not-allowed;
            }
          }
        }

        .sign-container-modal {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.233);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 4;

          .sign-modal {
            width: calc(100% - 76px);
            background: #fff;
            border-radius: 7px;
            border: 1px solid #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 6.88889px 68.88891px 0px #2929298c;

            .header-modal {
              display: flex;
              padding: 18px 21px 10px 40px;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .title {
                color: #373737;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }

              .close-btn-container {
                width: 28px;
                height: 28px;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .sign-area {
              width: calc(100% - 76px);
              border-radius: 10px;
              border: 1px solid #d9d9d9;
              background: #fff;
              height: 276px;
            }

            .sign-info {
              margin-top: 20px;
              width: calc(100% - 76px);
              color: #373737;
              text-align: center;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }

            .sign-btn-controls {
              margin-top: 25px;
              width: calc(100% - 116px);
              column-gap: 25px;
              grid-template-columns: 1fr 1fr;
              margin-bottom: 34px;

              .sign-btn {
                width: 100%;
                border-radius: 26.804px;
                background: #ff2424;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 10.722px;
                padding-top: 20px;
                padding-bottom: 20px;
                cursor: pointer;

                &:hover {
                  background: #d91d20;
                }

                &:active {
                  background: #b4191b;
                }
              }
            }
          }
        }

        .contract-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.233);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 4;

          .contract-modal {
            width: calc(100% - 220px);
            background: #fff;
            border-radius: 7px;
            border: 1px solid #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 6.88889px 68.88891px 0px #2929298c;

            .header-modal {
              display: flex;
              padding: 18px 21px 10px 21px;
              align-items: center;
              justify-content: space-between;
              width: 100%;

              .title {
                color: #373737;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }

              .close-btn-container {
                width: 28px;
                height: 28px;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .your-sign-show {
              width: calc(100% - 32px);
              border-radius: 10px;
              border: 1px solid #d9d9d9;
              background: #fff;
              height: 378px;
              overflow-y: auto;

              img {
                width: 100%;
              }
            }

            .sign-view-controls {
              margin-top: 30px;
              margin-bottom: 26px;
              display: flex;
              justify-content: center;
              align-items: center;

              .close-sign-view-btn {
                padding-inline: 60px;
                border-radius: 26.804px;
                background: #ff2424;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 10.722px;
                padding-top: 20px;
                padding-bottom: 20px;
                cursor: pointer;

                &:hover {
                  background: #d91d20;
                }

                &:active {
                  background: #b4191b;
                }
              }
            }
          }
        }

        .success-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.233);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 4;

          .success-modal {
            width: calc(100% - 220px);
            background: #fff;
            border-radius: 7px;
            border: 1px solid #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 6.88889px 68.88891px 0px #2929298c;

            .header-modal {
              display: flex;
              padding: 18px 21px 10px 21px;
              align-items: center;
              justify-content: flex-end;
              width: 100%;

              .title {
                color: #373737;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }

              .close-btn-container {
                width: 28px;
                height: 28px;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .icon-desc {
              width: 130px;
              height: 130px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .text-info-modal {
              margin-top: 20px;
              margin-bottom: 20px;
              color: #373737;
              text-align: center;
              font-family: Roboto;
              font-size: 19px;
              font-style: normal;
              font-weight: 700;
              line-height: 41px;
            }

            .desc-info-modal {
              width: calc(100% - 94px);
              color: #373737;
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;

              .heart-icon {
                font-size: large;
                color: red;
              }
            }

            .sign-view-controls {
              margin-top: 30px;
              margin-bottom: 26px;
              display: flex;
              justify-content: center;
              align-items: center;

              .close-sign-view-btn {
                padding-inline: 60px;
                border-radius: 26.804px;
                background: #ff2424;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 10.722px;
                padding-top: 20px;
                padding-bottom: 20px;
                cursor: pointer;

                &:hover {
                  background: #d91d20;
                }

                &:active {
                  background: #b4191b;
                }
              }
            }
          }
        }

        .fail-1-container {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.233);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 4;

          .success-modal {
            width: calc(100% - 220px);
            background: #fff;
            border-radius: 7px;
            border: 1px solid #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            box-shadow: 0px 6.88889px 68.88891px 0px #2929298c;

            .header-modal {
              display: flex;
              padding: 18px 21px 10px 21px;
              align-items: center;
              justify-content: flex-end;
              width: 100%;

              .title {
                color: #373737;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
              }

              .close-btn-container {
                width: 28px;
                height: 28px;
                cursor: pointer;

                img {
                  width: 100%;
                  height: 100%;
                }
              }
            }

            .icon-desc {
              width: 233px;
              height: 153px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .fail-info-text {
              margin-top: 20px;
              width: calc(100% - 100px);
              text-align: left;
              color: #373737;
              font-family: Roboto;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 22px;
            }

            .text-info-modal {
              margin-top: 20px;
              margin-bottom: 20px;
              color: #373737;
              text-align: center;
              font-family: Roboto;
              font-size: 19px;
              font-style: normal;
              font-weight: 700;
              line-height: 41px;
            }

            .desc-info-modal {
              width: calc(100% - 94px);
              color: #373737;
              text-align: center;
              font-family: Roboto;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;

              .heart-icon {
                font-size: large;
                color: red;
              }
            }

            .sign-btn-controls {
              margin-top: 25px;
              width: calc(100% - 50px);
              column-gap: 20px;
              grid-template-columns: 1fr 1fr;
              margin-bottom: 34px;

              .sign-btn {
                width: 100%;
                border-radius: 26.804px;
                background: #ff2424;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #fff;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 10.722px;
                padding-top: 20px;
                padding-bottom: 20px;
                cursor: pointer;

                &:hover {
                  background: #d91d20;
                }

                &:active {
                  background: #b4191b;
                }
              }

              .sign-close-btn {
                width: 100%;
                border-radius: 26.804px;
                background: white;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #ff2424;
                text-align: center;
                font-family: Roboto;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 10.722px;
                padding-top: 20px;
                padding-bottom: 20px;
                border: 1px solid #ff2424;
                cursor: pointer;

                &:hover {
                  background: #f4f4f4;
                }

                &:active {
                  background: #f4f4f4e1;
                }
              }
            }
          }
        }
      }

      .confirm-container {
        width: 100%;
        margin-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;

        .sign-confirm-btn {
          color: #fff;
          font-feature-settings: "clig" off, "liga" off;
          font-family: Roboto;
          font-size: 23px;
          font-style: normal;
          font-weight: 600;
          line-height: 21px;
          border-radius: 26.8px;
          background: #ed1e23;
          padding: 15px 50px;
          cursor: pointer;

          &:hover {
            background: #d91d20;
          }

          &:active {
            background: #b4191b;
          }
        }
      }
    }
  }

  .main-bottom {
    margin-top: 19px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .video-call-btn {
      border-radius: 26.804px;
      background: #ed1f24;
      width: 335px;
      height: 48px;
      flex-shrink: 0;
      color: #fff;
      text-align: center;
      font-family: Roboto;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 10.722px;

      &:hover {
        background: #d91d20;
      }

      &:active {
        background: #b4191b;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .vc-page-bg {
    color: black;

    .main-title {
      padding: 37px 10px 30px 47px;
      color: #393939;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Roboto;
      font-size: 21px;
      font-style: normal;
      font-weight: 600;
      line-height: 21px;
      text-transform: uppercase;
    }

    .main-body {
      align-items: center;
      height: fit-content;

      .vc-modal {
        width: 100%;
        border-radius: 10px;
        background: #fff;
        padding: 16px 20px 20px;

        .wall-bg {
          width: 100%;
          height: 189px;
          border-radius: 17px;

          > img {
            width: 100%;
            height: 100%;
            object-fit: none;
            object-position: center;
          }
        }

        .detail-instructions {
          margin-top: 24px;
          padding-inline: 0;
          width: 100%;

          display: flex;
          flex-direction: column;
          align-items: center;

          .title-instruction {
            margin-bottom: 15px;
          }
        }

        .desc-instructions {
          padding-inline: 0 !important;
        }

        .desc-text {
          max-width: 120px;
        }
      }

      .vc-show {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;

        .main-video {
          .connecting {
            .caller-avatar {
              width: 120px;
              height: 120px;
              border-radius: 120px;
              overflow: hidden;
            }

            .connecting-text {
              margin-top: 28px;
              font-size: 20px;
              text-align: center;
            }
          }

          .bottom-controls {
            bottom: 19px;
          }
        }
      }
    }

    .main-bottom {
      margin-top: 30px;

      .video-call-btn {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 532px) {
  .main-title {
    padding-inline: 0 !important;
    text-align: center;
  }

  .header-modal {
    padding-left: 25px !important;
  }

  .sign-area {
    width: calc(100% - 40px) !important;
  }

  .main-body {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .sign-modal,
  .success-modal,
  .contract-modal {
    width: 100% !important;
  }

  .sign-btn-controls {
    width: calc(100% - 60px) !important;
  }

  .sign-btn-controls {
    row-gap: 10px;
    grid-template-columns: 1fr !important;
  }
}
</style>
